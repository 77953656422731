<template>
  <Banner />
</template>

<script>
/* eslint-disable */
import Banner from '../components/Banner';

export default {
  name: 'HomeView',
  components: { Banner },
};
</script>

<style scoped></style>
